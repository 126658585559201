import React, {useEffect, useState} from 'react';
import s from './Sidebar.module.scss';
import {useAppSelector} from "../../app/hooks";
import GalleryPhoto from './../../assets/images/gallery.png';

const Sidebar = () => {
    let [currentDate, setCurrentDate] = useState<any>(new Date());
    let data: any = useAppSelector(state => state.main.data.settings);
    let isLoading = useAppSelector(state => state.main.isLoading);

    useEffect(() => {
        setInterval(() => {
            setCurrentDate(new Date());
        }, 1000);
    }, []);

    return (
        <aside className={s.sidebar}>
            <div className={s.sidebar__logo}>
                <img src={!isLoading ? data.settings.sideBarLogo : null} alt=""/>
            </div>
            <div className={s.timer}>
                <span>{("0" + currentDate.getDate()).slice(-2)} {currentDate.toLocaleString('en-EN', { month: 'long' })}, {currentDate.getFullYear()}</span>
                <strong>{currentDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}</strong>
                <span>{currentDate.toLocaleString('en-EN', { weekday: 'long' })}</span>
            </div>
            <div className={s.image}>
                <img src={!isLoading ? data.settings.lowerImgLogo : null} alt=""/>
            </div>
            {/*<div className={s.sun}>
                <span>Sun Rise & Sun Set</span>
                { sunTime &&
                    <div className={s.sun__info}>
                        <div className={s['sun__info-item']}>
                            <div className={s['sun__info-item__image']}>
                                <img src={Sun} alt=""/>
                            </div>
                            <div className={s['sun__info-item__info']}>
                                <strong>{new Date(sunTime.sunrise * 1000).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}</strong>
                                <span>Sun Rise</span>
                            </div>
                        </div>
                        <div className={s['sun__info-item']}>
                            <div className={s['sun__info-item__image']}>
                                <img src={Sunset} alt=""/>
                            </div>
                            <div className={s['sun__info-item__info']}>
                                <strong>{new Date(sunTime.sunset * 1000).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}</strong>
                                <span>Sun Set</span>
                            </div>
                        </div>
                    </div>
                }
            </div>*/}
        </aside>
    );
};

export default Sidebar;