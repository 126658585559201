import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {backendAPI, domain} from "../helpers/backendAPI";

const initialState = {
    data: {
        settings: {},
        timeouts: {
            maxDuration: {}
        },
        pages: <any>[

        ],
        calendar: {},
        album: {
            images: []
        },
    },
    isLoading: true
}

export const fetchData = createAsyncThunk(
    'main/fetchData',
    async () => {
        let data: any = {};
        let settings = await backendAPI.fetchGlobalSettings();
        let pages = await backendAPI.fetchAllPages();
        let timeouts = await backendAPI.fetchTimeouts(pages.items.map((i: any) => i.id));
        for (const i of pages.items) {
            switch (i.meta.type) {
                case "home.AlbumGallery":
                    let album = await backendAPI.fetchPage(i.id);
                    album.images = await backendAPI.fetchImages(album.image.map((i: any) => i.value));
                    data.album = album;
                    break;
                case "home.NoticePage":
                    let calendar = await backendAPI.fetchPage(i.id);
                    data.calendar = calendar;
                    break;
            }
        }
        return {settings, pages, timeouts, ...data};
    }
)

export const mainSlice = createSlice({
    name: 'main',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(fetchData.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(fetchData.fulfilled, (state, action) => {
            // @ts-ignore
            state.data = action.payload
            state.isLoading = false;
        })
    }
})