import React, {useEffect, useState} from 'react';
import {createBrowserRouter, Route, Router, RouterProvider, Routes, useLocation, useNavigate} from "react-router-dom";
import Home from "./pages/Home/Home";
import Weather from "./pages/Weather/Weather";
import Calendar from "./pages/Calendar/Calendar";
import Album from "./pages/Album/Album";
import {pageAPI} from "./services/PageService";
import {fetchHomeGallery, fetchHomeGalleryData, fetchHomePage} from "./slices/homeSlice";
import {useAppDispatch, useAppSelector} from "./app/hooks";
import {fetchCalendarPage} from "./slices/calendarSlice";
import {fetchAlbumPage, fetchImages} from "./slices/albumSlice";
import {fetchHourlyWeather, fetchWeatherImage, fetchWeatherPage} from "./slices/weatherSlice";
import {
    CSSTransition,
    TransitionGroup,
} from 'react-transition-group';
import Layout from "./components/Layout/Layout";
import Loader from "./components/Loader/Loader";
import {fetchGlobalSettings, fetchPageTimeouts} from "./slices/globalSlice";
import {fetchData} from "./slices/mainSlice";

const App = () => {
    let dispatch = useAppDispatch();

    let pages = useAppSelector(state => state.main.data.pages);
    let timeouts = useAppSelector(state => state.main.data.timeouts.maxDuration);
    let isLoading = useAppSelector(state => state.main.isLoading);

    let navigate = useNavigate();
    let location = useLocation();

    let startPages = () => {
         let timeout = 0;

         pages.items.forEach((i: any) => {
             if (i.meta.type == "home.AlbumGallery" || i.meta.type == "home.NoticePage") {
                 setTimeout(() => {
                     switch (i.meta.type) {
                         case "home.NoticePage":
                             navigate('/');
                             break;
                         case "home.AlbumGallery":
                             navigate('/album');
                             break;
                     }
                 }, timeout);
                 // @ts-ignore
                 timeout += timeouts[i.id] * 1000;
             }
         });

        setTimeout(() => {
            dispatch(fetchData());
        }, timeout);
    };

    useEffect(() => {
        dispatch(fetchData());
    }, []);

    useEffect(() => {
        if (!isLoading) {
            startPages();
        }
    }, [isLoading]);

    return (
        <>
            <Loader isHidden={!isLoading}/>
            <Layout>
                <TransitionGroup>
                    <CSSTransition classNames="fade"
                                   timeout={500}
                                   key={location.pathname}>
                        <Routes>
                            <Route path={'/'} element={<Calendar/>}/>
                            <Route path={'/album'} element={<Album/>}/>
                        </Routes>
                    </CSSTransition>
                </TransitionGroup>
            </Layout>
        </>
    );
};

export default App;