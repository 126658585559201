import axios from "axios";

export let domain = 'https://panel-vic1.versatilesign.com' + '/api';

export let instance = axios.create({
    baseURL: domain,
})

export const backendAPI = {
    // PAGES
    async fetchAllPages(){
        let response = await instance.get('/v2/pages/?format=json');
        return response.data;
    },
    async fetchPage(id: number){
        let response = await instance.get(`/v2/pages/${id}/?format=json`);
        return response.data;
    },
    // GLOBAL
    async fetchGlobalSettings(){
        let response = await instance.get(`/v3/global-settings`);
        return response.data;
    },
    async fetchTimeouts(pages: Array<number>){
        let response = await instance.post(`/v3/pageTimeout/`, {pages});
        return response.data;
    },
    // ALBUM
    async fetchImages(images: Array<string>){
        let response = await instance.post(`/v3/images`, {images});
        return response.data;
    },
}