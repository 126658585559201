import React, {useEffect, useState} from 'react';
import s from './Album.module.scss';
import Layout from "../../components/Layout/Layout";
import Gallery from "../../components/Gallery/Gallery";
import {useAppSelector} from "../../app/hooks";
import GalleryPhoto from './../../assets/images/gallery.png';

const Album = () => {
    let data: any = useAppSelector(state => state.main.data.album);
    let isLoading = useAppSelector(state => state.main.isLoading);

    return (
        <div className={s.album}>
            <h1>{data.title}</h1>
            {!isLoading && <Gallery items={Object.values(data.images.images)} delay={data.imageDuration * 1000}/>}
        </div>
    );
};

export default Album;