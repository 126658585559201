import React, {useEffect, useState} from 'react';
import s from './Calendar.module.scss';
import Layout from "../../components/Layout/Layout";
import Lesson from "../../components/Lesson/Lesson";
import {useAppSelector} from "../../app/hooks";

const Calendar = () => {

    let data: any = useAppSelector(state => state.main.data.calendar);
    let currentDate = new Date();
    let isLoading = useAppSelector(state => state.main.isLoading);

    return (
        <div className={s.calendar}>
            {/*<h1>{data.title}</h1>*/}
            <span>{("0" + currentDate.getDate()).slice(-2)} {currentDate.toLocaleString('en-EN', {month: 'long'})}, {new Date().getFullYear()}</span>
            <div className={s.calendar__items}>
                {!isLoading && data.notices.map((n: any, index: number) =>
                    <Lesson isAlt={true} title={n.value.Title} date={n.value.Date} key={n.id} timeout={index * 1000}/>
                )}
            </div>
        </div>
    );
};

export default Calendar;